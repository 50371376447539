/** @jsx jsx */
import { graphql, Link as GatsbyLink } from "gatsby"
import { jsx, Container } from "theme-ui"
import { motion } from "framer-motion"
import { TheLayout, BouncingArrow } from "../components"
import theme from "../gatsby-plugin-theme-ui"
import Img from "gatsby-image"

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
  reduced: { opacity: 0.93 },
}

const AltHome = ({ data }) => {
  // const thearticles = data.allArticleData.edges
  return (
    <TheLayout
      title="A Home Page"
      headerVariant="noscrim"
      footerbg="white"
      sx={{ bg: theme.colors.gray[8] }}
    >
      <div
        sx={{
          display: "grid",
          alignItems: "end",
          height: "100%",
          position: "relative",
          zIndex: 1,
          textAlign: "right",
        }}
      >
        <Container>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: "easeInOut", duration: 2 }}
          >
            <h1
              sx={{
                variant: "styles.alth1",
                color: "white",
                textShadow: "0.12em 0.12em 0.12em rgba(0,0,0,0.2)",
                letterSpacing: "tight",
              }}
            >
              Welcome&hellip; <br />
              You&rsquo;ve made&nbsp;it.
            </h1>

            <GatsbyLink
              to="/tiles"
              sx={{
                fontFamily: theme.fonts.links,
                display: "inline-flex",
                color: "white",
                border: "3px solid #c41230",
                letterSpacing: "2px",
                textTransform: "uppercase",
                bg: "transparent",
                textAlign: "center",
                textDecoration: "none",
                m: 0,
                borderRadius: 0,
                fontSize: "18px",
                fontWeight: "500",
                px: 3,
                py: 3,
                lineHeight: 1,
              }}
            >
              Find tiles <BouncingArrow />
            </GatsbyLink>
          </motion.div>
        </Container>
      </div>
      <motion.div
        initial="visible"
        animate="reduced"
        variants={variants}
        transition={{ ease: "easeInOut", duration: 3 }}
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: "#000",
        }}
      >
        <Img
          fluid={data.coverImage.childImageSharp.also}
          title="words"
          style={{ position: "unset", overflow: "visible" }}
          backgroundColor="#F9D6CE"
        />
      </motion.div>
    </TheLayout>
  )
}

export default AltHome

export const Alsoquery = graphql`
  query {
    coverImage: file(relativePath: { regex: "/hill-too-test/" }) {
      childImageSharp {
        fluid(
          # maxWidth: 800
          quality: 80
          duotone: { highlight: "#ffffff", shadow: "#663399" }
        ) {
          ...GatsbyImageSharpFluid
        }
        also: fluid(
          # maxWidth: 800
          quality: 80 # duotone: { highlight: "#ffffff", shadow: "#3bbad7" }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
